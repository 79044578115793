<template>
  <div class="king">
    <div class="super">
      <div class="holder">
        <Header />
        <div class="container">
          <div class="header-holder">
            <h1>Do More with <span class="shelt-span">Shelta</span></h1>
            <p>Rent, Lease or Buy properties</p>
          </div>

          <!-- New filter -->

          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <div>
                  <form>
                    <div class="form-group">
                      <select
                        class="form-control form-control-lg search-select"
                        v-model="cityid"
                      >
                        <option value="">Desired location?</option>
                        <option
                          v-for="(city, index) in cities"
                          :key="index"
                          v-bind:value="city.id"
                          >{{ city.name }}</option
                        >
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                <div class="amount__div">
                  <input
                    v-model="minprice"
                    class="form-control"
                    type="number"
                    placeholder="Minimum amount"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                <div class="amount__div">
                  <input
                    v-model="maxprice"
                    class="form-control"
                    type="number"
                    placeholder="Maximum amount"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="">
                  <div class="form-group">
                    <select
                      class="bedrooms__filter form-control"
                      id="exampleFormControlSelect1"
                      v-model="bedrooms"
                    >
                      <option value="">Bedrooms</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option class="3">3</option>
                      <option class="4">4</option>
                      <option class="5+">5+</option>
                    </select>
                  </div>
                  <!-- <p>Bedrooms</p> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                <div class="">
                  <div class="form-group">
                    <select class="bedrooms__filter form-control" id="which">
                      <!-- <option value="">Bedrooms</option> -->
                      <option value="rent">Rent</option>
                      <option value="buy">Buy</option>
                      <option value="sale">Sale</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                <div class="">
                  <div class="form-group">
                    <select
                      class="propertytype__filter form-control"
                      id="exampleFormControlSelect1"
                      v-model="subtype"
                    >
                      <option class="" value="">Property Type</option>
                      <option
                        class="Residential"
                        v-for="(sub, index) in subTypes"
                        :key="index"
                        v-bind:value="sub.id"
                        >{{ sub.subtype }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                <div class="wfh__filter">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="wfh"
                      :value="wfh"
                      v-model="wfh"
                    />
                    <label class="custom-control-label wfh-check" for="wfh"
                      >WFH Ready</label
                    >
                  </div>
                  <!-- <p>WHF Ready</p> -->
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="godiv">
                  <button
                    @click="searchProperties()"
                    class="btn btn-block gobtn"
                  >
                    SEARCH
                    <i
                      class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- New filter -->

          <!-- Filter section begins -->
          <!-- <div class="filter-holder">
            <div class="container">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <div>
                    <form>
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg search-select"
                        >
                          <option>Nigeria</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div>
                    <form>
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg search-select"
                        >
                          <option>Property Location</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div>
                    <form>
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg search-select"
                        >
                          <option>Property Category</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div>
                    <form>
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg search-select"
                        >
                          <option>Property Type</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div>
                    <form>
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg search-select"
                        >
                          <option>Minimum Amount</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div>
                    <form>
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg search-select"
                        >
                          <option>Maximum Amount</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div class="advance-search-holder">
                    <p>
                      Advanced search here
                      <i class="fa fa-arrow-down down-icon"></i>
                    </p>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div>
                    <form>
                      <div class="form-group">
                        <input
                          class="form-control form-control-lg search-select"
                          type="text"
                          placeholder="PIN(e.g. S2201)"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div>
                    <button class="btn search-btn btn-block form-control-lg">
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Filter section ends -->
        </div>
      </div>
      <!-- Property list begins -->

      <div class="property-list-holder">
        <!-- List header -->
        <div class="list-header">
          <h3>
            Best property offers and deals
            <span class="around-you-span">around you...</span>
          </h3>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus
            laoreet felis imperdiet pharetra odioique fermentum.
          </p> -->
        </div>
        <!-- List header -->

        <!-- List grid begins -->
        <!-- <div v-if="loader2" class="loader-div pt-3 pb-3">
          <i class="fa fa-spinner fa-spin fa-2x ml-3 f-12  btn-loader">
          </i>
        </div> -->
        <!-- Skeleton loader -->
        <Skeleton v-if="loader2" class="property-list" />
        <!-- Skeleton loader -->
        <div v-if="!loader2" id="properties" class="property-list">
          <div class="container-fluid">
            <div class="row">
              <div v-if="properties.length <= 0" style="margin: auto">
                No property to show you.
              </div>
              <!-- col-md-4 col-lg-4 -->
              <div
                class="col-md-6 col-lg-4 col-sm-12 col-xl-4"
                v-for="(property, index) in properties"
                :key="index"
              >
                <div class="list-grid">
                  <div class="list-img">
                    <div class="for-rent">
                      <p>{{ property.businesstypename }}</p>
                    </div>
                    <div
                      id="carouselExampleControls"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img
                            @click="moveTo(property.pin)"
                            class="d-block prop__img"
                            :src="property.oneimageurl"
                            alt="First slide"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="property-details-holder">
                      <div class="card property-details-wrap">
                        <div class="card-body">
                          <div class="details-header">
                            <p class="card-title">{{ property.title }}</p>
                            <!-- <p @click="moveTo(property.pin)">I'm Intrested</p> -->
                          </div>
                          <div class="location-holder">
                            <img src="../assets/images/map-point.svg" alt="" />
                            <small
                              >{{ property.city }}, {{ property.state }}</small
                            >
                          </div>
                          <p class="details-text">
                            {{ property.description }}
                          </p>
                          <h2 class="property-amount">
                            &#8358;
                            {{ Number(property.price).toLocaleString() }}
                            <span
                              v-if="property.businesstypename != 'Sale'"
                              class="duration"
                              >Per annum</span
                            >
                          </h2>
                          <div class="property-option">
                            <div class="facility-holder">
                              <div class="facility-wrap">
                                <img src="../assets/images/bed.svg" alt="" />
                                <p>{{ property.bedrooms }} bedrooms</p>
                              </div>
                              <div class="facility-wrap">
                                <img
                                  src="../assets/images/bathroom.svg"
                                  alt=""
                                />
                                <p>{{ property.bathrooms }} bathrooms</p>
                              </div>
                              <div class="facility-wrap">
                                <img src="../assets/images/toilet.svg" alt="" />
                                <p>{{ property.toilets }} toilets</p>
                              </div>
                              <div class="facility-wrap">
                                <img
                                  src="../assets/images/parking.svg"
                                  alt=""
                                />
                                <p>{{ property.parking }} parking</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="view-more">
        <button class="btn">VIEW MORE LISTING</button>
      </div> -->
      <!-- List grid ends -->
    </div>
    <!-- Property list holder ends -->

    <!-- Footer-starts -->
    <FooterMain />
    <!-- Footer-ends -->
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Skeleton from "../components/SkeletonLoader.vue";
import Header from "../views/Header";
import { Service } from "../store/service";
import $ from "jquery";
const Api = new Service();

export default {
  name: "PropertyListHome",
  components: {
    Header,
    FooterMain,
    Skeleton
  },
  data() {
    return {
      loader2: false,
      properties: [],
      wfh: "",
      sliderOne: document.getElementById("slider-1"),
      sliderTwo: document.getElementById("slider-2"),

      displayValOne: document.getElementById("range1"),
      displayValTwo: document.getElementById("range2"),
      sliderTrack: document.querySelector(".slider-track"),
      // sliderMaxValue: document.getElementById('slider-1').max,

      cities: "",
      subTypes: "",

      naira: "&#8358;",

      minGap: 0,
      minRange: "0",
      maxRange: "100000000",

      subtype: "",
      cityid: "",
      bedrooms: "",
      maxprice: "",
      minprice: "",
      wfh: "",
      loader: false,
      blogs: []

      // sliderOne: any,
      // sliderTwo: any,
      // displayValOne: any,
      // displayValTwo: any,
      // minGap: 0,
      // sliderTrack: any,
      // sliderMaxValue: any,
    };
  },
  mounted() {
    this.fetchProperties();
    // this.slideOne();
    // this.slideTwo();
    this.getCities();
    this.getAllSubTypes();
    // $('#dropdownMenu2').on('hide.bs.dropdown', function () {
    //   return false;
    // });
  },
  methods: {
    // moveTo: function(id) {
    //   this.$router
    //     .push({
    //       path: `/blog/${id}`,
    //     })
    //     .catch(() => {});
    // },

    openDropdownMenu(bvEvent) {
      const open = document.getElementById("priceMenuRange");
      if (open.classList.contains("removeClass")) {
        open.classList.remove("removeClass");
        open.classList.add("addClass");
      } else {
        open.classList.add("addClass");
      }
    },
    closeDropdownMenu: function() {
      const close = document.getElementById("priceMenuRange");
      if (close.classList.contains("addClass")) {
        close.classList.remove("addClass");
        close.classList.add("removeClass");
      } else {
        close.classList.add("removeClass");
      }
    },
    searchProperties: async function() {
      this.loader = true;
      if (this.wfh === true) {
        this.wfh = "wfh";
      } else {
        this.wfh = "";
      }
      try {
        await Api.postRequest(`searchproperties`, {
          subtype: this.subtype,
          cityid: this.cityid,
          bedrooms: this.bedrooms,
          maxprice: this.maxprice,
          minprice: this.minprice,
          wfh: this.wfh
        })
          .then(res => {
            this.properties = res.data.properties;
            this.loader = false;
            document.getElementById("properties").scrollIntoView();
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getCities: function() {
      Api.getRequest(`fetchcitiesinnigeria`)
        .then(res => {
          this.$store.commit("setApiWarning", "Loading states...");
          if (res.data.success) {
            this.cities = res.data.cities;
            this.$store.commit("setApiWarning", "");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getAllSubTypes: function() {
      Api.getRequest(`fetchpropertysubtypes`)
        .then(res => {
          this.$store.commit("setApiWarning", "Loading states...");
          if (res.data.success) {
            this.subTypes = res.data.subtypes;
            this.$store.commit("setApiWarning", "");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    slideOne: function() {
      const sliderOne = document.getElementById("slider-1");
      const sliderTwo = document.getElementById("slider-2");
      const displayValOne = document.getElementById("range1");
      this.minRange = sliderOne.value;
      const sliderMaxValue = document.getElementById("slider-1").max;
      if (
        parseInt(sliderTwo.value) - parseInt(sliderOne.value) <=
        this.minGap
      ) {
        sliderOne.value = parseInt(sliderTwo.value) - this.minGap;
        this.minRange = parseInt(this.maxRange) - this.minGap;
      }
      displayValOne.textContent = sliderOne.value;
      this.minprice = parseInt(sliderOne.value, 10);
      this.fillColor();
    },
    slideTwo: function() {
      const sliderOne = document.getElementById("slider-1");
      const sliderTwo = document.getElementById("slider-2");
      const displayValOne = document.getElementById("range1");
      const displayValTwo = document.getElementById("range2");
      this.maxRange = sliderTwo.value;
      this.sliderMaxValue = document.getElementById("slider-1").max;
      if (
        parseInt(sliderTwo.value) - parseInt(sliderOne.value) <=
        this.minGap
      ) {
        sliderTwo.value = parseInt(sliderOne.value) + this.minGap;
        this.maxRange = parseInt(this.minRange) - this.minGap;
      }
      displayValTwo.textContent = sliderTwo.value;
      this.maxprice = sliderTwo.value;
      this.fillColor();
    },
    fillColor: function() {
      const sliderOne = document.getElementById("slider-1");
      const sliderTwo = document.getElementById("slider-2");
      const sliderMaxValue = document.getElementById("slider-1").max;
      const percent1 = (sliderOne.value / sliderMaxValue) * 100;
      const percent2 = (sliderTwo.value / sliderMaxValue) * 100;
      const sliderTrack = document.querySelector(".slider-track");
      sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}%, #3264fe ${percent1}%, #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
    },
    openPrice: function() {
      const openRange = document.getElementById("priceRange");
      openRange.style.display = "show";
    },
    moveTo: function(id) {
      this.$router.push({
        path: `/property/${id}`
      });
    },
    fetchProperties: async function() {
      this.loader2 = true;
      try {
        await Api.getRequest(`fetchpropertiesrandomly`).then(res => {
          this.properties = res.data.properties;
          this.loader2 = false;
        });
      } finally {
        // console.log("Closed connection");
      }
    },
    readMoreBlogPost() {
      const dots = document.getElementById("dotsForMore");
      const moreText = document.getElementById("more");
      const btnMore = document.getElementById("moreBtn");
      if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnMore.innerHTML = "Read more";
        moreText.style.display = "none";
      } else {
        dots.style.display = "none";
        btnMore.innerHTML = "Read less";
        moreText.style.display = "inline";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
// Gotham: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
// Gotham2: url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
.addClass {
  display: block;
}
.removeClass {
  display: none;
  visibility: hidden;
}
.king {
  // background: url("../assets/images/bg-img-home.svg") no-repeat center center
  //   fixed;
  // background-blend-mode: color-burn;
  // background-color: rgba(255, 255, 255, 0.9);
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
}
.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;

  background: url("../assets/images/bg-img-home.svg") no-repeat center center;
  background-blend-mode: color-burn;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Top header begins
.header-holder {
  padding-top: 13rem;
}
.header-holder > h1 {
  font-family: Gotham;
  font-style: normal;
  font-weight: 100;
  font-size: 70px;
  line-height: 74px;
  align-items: center;
  color: $secondary;
}
.header-holder > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  align-items: center;
  color: $secondary;
}
.shelt-span {
  color: $primary;
  font-weight: 800;
}
// Top header ends

// Filter section begins

// Main filter new
.price__menu {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(15px, 57px, 0px);

  float: left;
  min-width: 27rem;
  min-height: 12rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 10px 15px;
}

.price__filter {
  background: #ffffff;
  border: 0.5px solid #0033ea;
  min-height: 56.74px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
    font-family: Gotham;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 50px;
    color: $secondary;
  }
}

.price__range {
  position: relative;
}
input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 144px;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}
.slider-track {
  width: 100%;
  height: 5px;
  background-color: rgb(218, 218, 229);
  position: absolute;
  margin: auto;
  top: 140px;
  bottom: 0;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}
input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  height: 5px;
}
input[type="range"]::-ms-track {
  -webkit-appearance: none;
  height: 5px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-color: $primary;
  cursor: pointer;
  border-radius: 50px;
  // pointer-events: auto;
  margin-top: -9px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-color: $primary;
  cursor: pointer;
  border-radius: 50px;
  pointer-events: auto;
  margin-top: -9px;
}
input[type="range"]::-moz-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-color: $primary;
  cursor: pointer;
  border-radius: 50px;
  pointer-events: auto;
  margin-top: -9px;
}
input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-color: $primary;
  cursor: pointer;
  border-radius: 50px;
  pointer-events: auto;
  margin-top: -9px;
}
input[type="range"]:active::-webkit-slider-thumb {
  background-color: $whiteText;
  border: 3px solid $primary;
}

.range__values {
  background-color: $primary;
  width: fit-content;
  position: relative;
  margin: auto;
  padding: 10px 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  color: $whiteText;
  font-family: Gotham;
}
.range__values::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid $primary;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin: auto;
  bottom: -14px;
  left: 0;
  right: 0;
}

// Ranage

.bedrooms__filter {
  background: #ffffff;
  // border: 0.5px solid $primary;
  min-height: 56.74px;
  margin: auto;
  // display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: none;
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  color: $secondary;
  border-radius: 0;
}

.amount__div {
  input {
    // border: 0.5px solid #0033ea;
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    min-height: 56.74px;
    font-family: Gotham2;
    font-style: italic;
    font-weight: 300;
    color: #000000;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// propertytype__filter

.propertytype__filter {
  background: #ffffff;
  // border: 0.5px solid #0033ea;
  min-height: 56.74px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: none;
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  color: $secondary;
  border-radius: 0;
  // p {
  //   margin-bottom: 0;
  //   font-family: Gotham;
  //   font-style: italic;
  //   font-weight: 300;
  //   font-size: 14px;
  //   line-height: 50px;
  //   color: $secondary;
  // }
}

.wfh__filter {
  background: $whiteText;
  // border: 0.5px solid #0033ea;
  min-height: 56.74px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}

.wfh-check {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  color: $secondary;
}
.gobtn {
  background: $primary;
  color: $whiteText;
  font-family: Gotham;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  box-shadow: none;
  outline: 0;
  min-height: 56.74px;
}
.godiv {
  // margin: auto;
  // padding-bottom: 10px;
}

.showDrop {
  display: block;
}
.hideDrop {
  display: none;
}
.closeDropdown {
  position: absolute;
  bottom: 2%;
  right: 2.5%;
  display: flex;
  button {
    color: $whiteText;
    background: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    box-shadow: none;
    outline: 0;
    margin-bottom: 0.5rem;
  }
}

// .wfh-check::before {
//     margin-top: 0.7rem;
// }

// Main filter new ends

.search-select {
  // border: 0.5px solid $primary;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  min-height: 56.74px;
  font-family: Gotham2;
  font-style: italic;
  font-weight: 300;
  color: $secondary;
}

.search-btn {
  font-family: Gotham;
  background-color: $primary;
  color: $whiteText;
  height: 2.9rem;
  border-radius: 0;
}
.advance-search-holder {
  display: flex;
}
.advance-search-holder > p {
  padding-top: 1rem;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  align-items: center;
  color: $secondary;
}
.down-icon {
  padding-left: 1rem;
}
.range__value {
  position: absolute;
  bottom: 11%;
  display: flex;
  justify-content: space-between;
  div {
    input {
      height: 45px;
      border: 1px solid $primary;
      box-sizing: border-box;
      // padding: 0 10px;
    }
    h6 {
      padding-top: 0.5rem;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      // align-items: center;
      color: $secondary;
    }
  }
  div:first-child {
    input {
      margin-right: 5px;
    }
  }
  div:nth-child(2) {
    input {
      margin-left: 5px;
    }
  }
}

.range__value2 {
  // position: absolute;
  // bottom: 5%;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  div {
    input {
      height: 45px;
      border: 1px solid $primary;
      box-sizing: border-box;
      // padding: 0 10px;
    }
    h6 {
      padding-top: 0.5rem;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      // align-items: center;
      color: $secondary;
    }
  }
  div:first-child {
    input {
      margin-right: 5px;
    }
  }
  div:nth-child(2) {
    input {
      margin-left: 5px;
    }
  }
}

// .range-holder {
//   input {

//   }
// }

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  color: $secondary;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  color: $secondary;
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  color: $secondary;
}

// Filter section ends

// Property list  header
.property-list-holder {
  padding-top: 3rem;
}
.list-header > h3 {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 34px;
  align-items: center;
  color: $secondary;
}

.list-header > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $secondary;
  max-width: 40rem;
  text-align: center;
  margin: auto;
}
.around-you-span {
  color: $primary;
  font-weight: 700;
}
// Property list header

// Property list grid
.property-list {
  padding: 5rem 0 2rem 0;
}

.list-img {
  position: relative;
  width: 100%;
  margin-bottom: 15rem;
}
.for-rent {
  margin: auto;
  top: -4%;
  left: 36%;
  position: absolute;
  width: 26%;
  z-index: 999;
}
.for-rent > p {
  background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
  color: $whiteText;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  // width: 103px;
  // height: 24px;
  font-size: 12px;
  line-height: 23px;
}

.prop__img {
  width: 100% !important;
  height: 266px !important;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 10px 30px #e2e2e2;
}

.property-details-holder {
  position: absolute;
  top: 92%;
  left: 4%;
  width: 92%;
}
.property-details-wrap {
  background: #ffffff;
  box-shadow: 0px 10px 30px #e2e2e2;
  border-radius: 5px 30px;
}
.details-header {
  // padding-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.details-header > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $secondary;
  margin-bottom: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.details-header > p:nth-child(2) {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  text-decoration-line: underline;
  color: $primary;
  margin-bottom: 0;
  cursor: pointer;
}

.location-holder {
  display: flex;
  padding: 0.5rem 0 0.6rem 0;
}
.location-holder > img {
  width: 0.7rem;
}
.location-holder > small {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: start;
  padding-left: 0.5rem;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.25);
}

.details-text {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: $secondary;
  text-align: left;
  padding-top: 0.2rem;
}

.property-amount {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 13px;
  text-align: left;
  color: $secondary;
  padding-top: 0.7rem;
}
.duration {
  font-size: 16px;
}
.facility-holder {
  display: flex;
  justify-content: space-between;
  padding-top: 0.7rem;
}
// .facility-wrap {
//   // margin: auto;
// }
.facility-wrap > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 9px;
  color: $secondary;
  margin-bottom: 0;
  // padding: 0 0.1rem;
}

.view-more {
  padding-bottom: 5rem;
  //  width: 214px;
  //  height: 61px;
}
.view-more > button {
  background: linear-gradient(180deg, #ffffff 0%, rgba(0, 51, 234, 0.62) 100%),
    linear-gradient(180deg, #0033ea 0%, #0033ea 100%);
  border-radius: 10px;
  border: none;
  color: $whiteText;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  width: 214px;
  height: 61px;
}

.bedroom__numebr {
  span {
    border: 1px solid #0033ea;
    box-sizing: border-box;
    color: #0033ea;
    padding: 4px 6px;
    // padding: 10px;
  }
}
.span1 {
  padding: 8px 20px;
  background: #0033ea;
  box-sizing: border-box;
  color: $whiteText;
}
// Property list

// Blog post begins
.blog-post-holder {
  position: relative;
  margin-bottom: 2rem;
}
.blog-holder {
  background: #f5f3f3;
  box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.05);
}
.blog-img {
  min-width: 319px;
  min-height: 293px;
  cursor: pointer;
}
.blog-header {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.blog-child > div:nth-child(2) {
  padding-bottom: 3rem;
}
.blog-header > h2 {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.blog-post-details {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.38) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(0, 0, 0, 0.7);
  opacity: 0.7;
  position: absolute;
  bottom: 0%;
  width: 100%;
}
.blog-post-child {
  padding: 0 1rem;
}
.blog-details-header > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  text-align: start;
  color: $whiteText;
  margin-bottom: 0;
  white-space: nowrap;
  // width: 273px;
  overflow: hidden;
  text-overflow: "---";
  padding-top: 15px;
  margin-bottom: 0 !important;
  // overflow: hidden;
  // text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.blog-details-header > p > p {
  margin-bottom: 0;
}
.blog-post-child > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  text-align: start;
  color: $whiteText;
  margin-bottom: 0;
  max-height: 2rem;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#more {
  display: none;
}
#moreBtn {
  box-shadow: none;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-decoration-line: underline;
  color: #839df8;
  display: flex;
  cursor: pointer;
}

.bloger-info-holder {
  display: flex;
  justify-content: space-between;
}
.blogger-profile > img {
  width: 35px;
  height: 35px;
  // object-fit: cover;
  border-radius: 100px;
}
.blogger-profile > span {
  padding-left: 0.4rem;
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 50px;
  text-align: start;
  color: $whiteText;
}
.read-time > p {
  font-family: Gotham;
  font-family: Gotham;
  font-weight: 600;
  font-size: 13px;
  line-height: 50px;
  color: $whiteText;
  margin-bottom: 0;
}
// Blog post ends

@media only screen and (max-width: 599px) {
  .header-holder {
    padding-top: 9rem;
  }
  .header-holder > h1 {
    font-size: 40px;
    line-height: 53px;
    padding-bottom: 1rem;
  }
  .header-holder > p {
    font-size: 18px;
    line-height: 29px;
  }
  .filter-holder {
    padding-bottom: 2rem;
  }
  .list-header > h3 {
    font-size: 22px;
  }
  .property-list {
    padding: 3rem 0 2rem 0;
  }
  .facility-wrap > p {
    font-size: 10px;
    line-height: 16px;
  }
  .price__menu {
    min-width: 100%;
  }
  .price__filter {
    margin-bottom: 15px;
  }
  .search-select {
    min-height: 46.74px;
  }
  .amount__div {
    input {
      min-height: 46.74px;
    }
  }
  .bedrooms__filter {
    min-height: 46.74px;
  }
  .wfh__filter {
    min-height: 46.74px;
  }
  .gobtn {
    // margin-top: 1rem;
    width: 10rem;
    margin: auto;
    min-height: 43.74px;
  }
  .godiv {
    padding: 1rem 0;
  }
  .range__values {
    font-size: 17px;
  }
  .price__filter {
    justify-content: space-between;
    padding: 0 23px;
  }
  .wfh__filter {
    justify-content: left;
    padding-left: 1rem;
  }
  .range__value {
    div {
      input {
        width: 8rem;
      }
    }
  }
  .amount__div {
    padding-bottom: 1rem;
  }
  .blog-img {
    height: 100%;
  }
  // .closeDropdown {
  //   // right: 38%;
  //   button {
  //     font-size: 13px;
  //   }
  // }
}
@media screen and (max-width: 320px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
}

@media screen and (max-width: 360px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .advance-search-holder {
    display: block;
  }
}
</style>
